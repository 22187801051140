<template>
    <div class="bg-custom-primary py-2 ">
        <div class="container">
            <div class="d-flex justify-content-end">
                <ul class="list-unstyled d-flex mb-0">
                    <li><a class="link-dark text-white"
                            href="https://www.facebook.com/institutocastelobranconiquelandia?locale=pt_BR"
                            target="_blank"><i class="bi bi-facebook icon-22"></i></a></li>
                    <li class="ms-3"><a class="link-dark text-white"
                            href="https://www.instagram.com/institutocastelobranco/?igsh=MXZ1cmpqcTRuazViYw%3D%3D"
                            target="_blank"><i class="bi bi-instagram icon-22 p-2"></i></a></li>
                    <li class="ms-3"><a class="link-dark text-white" href="https://wa.me/5562985815776" target="_blank"><i
                                class="bi bi-whatsapp icon-22"></i></a></li>
                </ul>

            </div>
        </div>
    </div>

    <div class="shadow-sm">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light mt-0" aria-label="Eighth navbar example">
                <div class="container">

                    <img src="../assets/logo.png" alt="Logo da instituição Castelo Branco" width="50" height="50"
                        class="d-inline-block align-text-top" />

                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarsExample07">
                        <ul class="navbar-nav ms-auto mb-lg-0">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link"
                                    :class="{ active: $route.path === '/' }">Início</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/contato" class="nav-link "
                                    :class="{ active: $route.path === '/contato' }">Contato</router-link>
                            </li>
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="dropdown10" data-bs-toggle="dropdown"
                                        aria-expanded="false">Cursos</a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdown10">
                                        <li class="dropdown-item"><a class="nav-link"
                                                href="https://niquelandia.cedtec.com.br/" target="_blank">Cursos 
                                                parceria Cedtec</a></li>
                                        <li id="app" class="dropdown-item"><a class="nav-link" target="_blank"
                                                href="https://instituto-castelo-branco.s3.amazonaws.com/edital-2024.pdf"
                                                download="edital-2024.pdf" type="application/pdf"><i
                                                    class="bi bi-filetype-pdf"></i> Curso de redação -
                                                Edital</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'NavBar',
    props: {
        title: String
    }
}
</script>
  
<style>
.bg-custom-primary {
    background: #01aae1;
}

.bg-custom-secondary {
    background: #7cb637;
}
</style>
  