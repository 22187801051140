// src/store/store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    token: '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    }
  },
  actions: {
    updateToken({ commit }, token) {
      commit('setToken', token);
    }
  }
});