import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ToastPlugin from 'vue-toast-notification';
import axios from 'axios';
import store from './store/store';
import 'vue-toast-notification/dist/theme-bootstrap.css';


// Configuração global do Axios
axios.defaults.baseURL = 'https://5b0nwf324c.execute-api.us-east-1.amazonaws.com/Prod/';

const app = createApp(App);

// Configuração Axios para o objeto globalProperties do app
app.config.globalProperties.$axios = axios;

// Definindo o filtro global 'truncate'
app.config.globalProperties.$filters = {
    truncate(text, length, suffix) {
        if (text.length > length) {
        return text.substring(0, length) + suffix;
        } else {
        return text;
        }
    }
};

// Usar os plugins
app.use(ToastPlugin, { position: 'top', duration: 6000 });
app.use(router);
app.use(store);

// Montar a aplicação
app.mount('#app');