import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/inscricoes',
    name: 'inscricoes',
    meta: { title: 'Inscrições' },
    component: () => import(/* webpackChunkName: "inscricoes" */ '../views/EnrollmentView.vue')
  },
  {
    path: '/contato',
    name: 'contato',
    meta: { title: 'Contato' },
    component: () => import(/* webpackChunkName: "login" */ '../views/ContactsView.vue')
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    meta: { title: 'Admin - Login' },
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminLogin.vue')
  },
  {
    path: '/admin/painel',
    name: 'painel',
    meta: { title: 'Admin - Painel' },
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminPanel.vue')
  },
  {
    path: '/admin/criar-card-de-publicacao',
    name: 'criar-card-de-publicacao',
    meta: { title: 'Admin - Criar publicacao' },
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminCreatePublicationCard.vue')
  },
  {
    path: '/admin/editar-card-de-publicacao/:id',
    name: 'editar-card-de-publicacao',
    meta: { title: 'Admin - Editar publicacao' },
    props: true,
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminEditPublicacionCard.vue')
  },
  {
    path: '/admin/criar-topicos',
    name: 'criar-topicos',
    meta: { title: 'Admin - Criar topicos' },
    props: true,
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminCreateTopics.vue')
  },
  {
    path: '/admin/editar-topico/:topicId',
    name: 'editar-topico',
    meta: { title: 'Admin - Editar topicos' },
    props: true,
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminEditTopics.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Instituto castelo branco';
  next();
});

export default router
