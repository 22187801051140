<template>
  <NavBar />

  <div class="container ">
    <div class="container p-5 mb-5 mt-1">
      <div class="text-center">
        <div class="container ">
          <img class="d-block mx-auto mb-3" src="../assets/logo.png" alt="Logo da instituição Castelo Branco"
            width="170" height="170">
          <h1 class="display-5 fw-bold">Escreva o Seu Futuro no Enem!</h1>
        </div>
        <div class="mx-auto">
          <p class="lead px-5">Seja protagonista da sua jornada acadêmica! Ao matricular-se no Instituto
            Castelo Branco, você não está apenas se inscrevendo em aulas de redação, mas abrindo as portas
            para um futuro repleto de oportunidades!
          </p>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div class="row mb-5">
          <!-- Missão -->
          <div class="col-md-6 text-center">
              <i class="fas fa-bullseye fa-3x text-primary"></i>
              <h2 class="my-3">Missão</h2>
              <p class="lead">Capacitar profissionais da saúde e preparar estudantes para desafios acadêmicos e cursos profissionalizantes com educação de excelência, inovadora e acessível, transformando vidas por meio do conhecimento.</p>
          </div>

          <!-- Visão -->
          <div class="col-md-6 text-center">
              <i class="fas fa-eye fa-3x text-success"></i>
              <h2 class="my-3">Visão</h2>
              <p class="lead">Ser reconhecido como referência nacional em educação técnica, especialização na área da saúde, e cursos profissionalizantes, bem como em preparação para o ENEM e concursos públicos, inovando continuamente com tecnologias e metodologias pedagógicas modernas.</p>
          </div>
      </div>

      <!-- Portfólio de Cursos -->
      <div class="row mb-5">
          <div class="col text-center">
              <i class="fas fa-book-open fa-3x text-warning"></i>
              <h2 class="my-3">Portfólio de Cursos</h2>
          </div>
      </div>

      <div class="row mb-5">
          <div class="col-md-6 mb-3">
              <div class="card h-100">
                  <div class="card-body">
                      <h5 class="card-title">Curso Técnico em Enfermagem</h5>
                      <p class="card-text">1800 horas</p>
                  </div>
              </div>
          </div>
          <div class="col-md-6 mb-3">
              <div class="card h-100">
                  <div class="card-body">
                      <h5 class="card-title">Curso de Especialização Técnica em Instrumentação Cirúrgica</h5>
                      <p class="card-text">600 horas</p>
                  </div>
              </div>
          </div>
          <div class="col-md-6 mb-3">
              <div class="card h-100">
                  <div class="card-body">
                      <h5 class="card-title">Curso de Redação Preparatório ENEM e concursos públicos</h5>
                  </div>
              </div>
          </div>
          <div class="col-md-6 mb-3">
              <div class="card h-100">
                  <div class="card-body">
                      <h5 class="card-title">Cursos de Qualificação, Aperfeiçoamento Profissional e treinamentos In company</h5>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <!-- Fundo escurecido -->
    <div v-if="floatingDiv.showFloatingDiv" class="overlay" @click="closeFloatingDiv">
      <!-- Div flutuante -->
      <div class="floating-div container z-3 position-absolute p-5 rounded-3">
        <div class="row justify-content-center">
          <div class="col-8 col-md-8 text-center content-container">
            <img v-if="floatingDiv.imgName" :src="'https://instituto-castelo-branco-images.s3.amazonaws.com/' + floatingDiv.imgName" alt="Placeholder Image" class="img-fluid mb-5">
            <h1 class="display-5 fw-bold mb-5">{{ floatingDiv.title }}</h1>
            <p class="card-text mb-5">{{ floatingDiv.paragraph }}</p>
            <button class="btn btn-secondary" @click.stop="closeFloatingDiv">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Topicos com publicacoes -->
    <div class="row justify-content-center" v-for="topic in topicsWithPublications" :key="topic.id">
      <h1 class="display-5 fw-bold text-center mb-5">{{ topic.title }}</h1>

      <div class="col-lg-4 mb-5" v-for="publicationCard in topic.publications" :key="publicationCard.id">
        <div class="card">
          <img v-if="publicationCard.imgName"
            :src="'https://instituto-castelo-branco-images.s3.amazonaws.com/' + publicationCard.imgName"
            class="card-img-top" alt="Placeholder image">
          <div class="card-body text-center">
            <h5 class="card-title">{{ publicationCard.title }}</h5>
            <p class="card-text">{{ truncatedText(publicationCard.paragraph) }} <span v-if="isTruncatedText(publicationCard.paragraph)" class="link-button" @click="openFloatingDiv(publicationCard.id)">Ler mais</span></p>
            
            <router-link v-if="publicationCard.isInternalLink == true"
              :to="{ path: '/inscricoes', query: { type_enrollment: publicationCard.title, description_enrollment: publicationCard.paragraph, is_form_with_responsible: publicationCard.isFormWithResponsible } }"
              class="bg-custom-primary btn bg-custom-primary text-white btn-sm mt-2"
              :class="{ active: $route.path === '/inscricoes' }">{{ publicationCard.textButtonSubmit }} <i
                class="fas fa-arrow-right ml-2"></i></router-link>
            <a :href="publicationCard.link" target="_blank" class="bg-custom-primary btn bg-custom-primary text-white btn-sm mt-2" v-if="publicationCard.isInternalLink == false && publicationCard.link">
              {{ publicationCard.textButtonSubmit }} <i
                class="fas fa-arrow-right ml-2"></i>
            </a>
            <div class="d-flex justify-content-end mt-3">
              <small class="text-muted">{{ getTimeSinceCreation(publicationCard.createdAt) }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
import { ptBR } from 'date-fns/locale';
import { formatDistanceToNow, parseISO } from 'date-fns';

export default {
  name: 'HomeView',
  components: {
    NavBar,
    Footer
  },
  data() {
    return {
      publicationsCards: [],
      allTopics: [],
      topicsWithPublications: [],
      floatingDiv: {
        showFloatingDiv: false,
        title: '',
        paragraph: '',
        imgName: null
      }
    }
  },
  created() {
    this.fetchAllTopics();
  },
  methods: {
    async aggregatePublicationsToTopics() {
      for (const topic of this.allTopics) {
        topic.publications = [];

        for (const publication of this.publicationsCards) {
          if (publication.topic === topic.id) {
            topic.publications.push(publication);
          }
        }

        // check topic publications is empty array
        if (topic.publications.length === 0) {
          continue;
        }

        this.topicsWithPublications.push(topic);
      }

      console.log('topicsWithPublications', this.topicsWithPublications);
    },
    async fetchAllTopics() {
        try {
            const response = await this.$axios.get('/topics/get-all/limit/100/page-size/1');

            this.allTopics = response.data;

            this.fetchPublicationsCards();
        } catch (error) {
            console.error("Erro ao buscar topicos:", error);

            this.$toast.error("Ops! Algo deu errado para listar topicos.", {
                // optional options Object
            });
        }
    },
    async fetchPublicationsCards() {
      try {
        const response = await this.$axios.get('/publication-card/list/100/1');

        this.publicationsCards = response.data.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        
        this.aggregatePublicationsToTopics();
      } catch (error) {
        console.error("Erro ao buscar publicacoes:", error);

        this.$toast.error("Ops! Algo deu errado para listar publicacoes.", {
          // optional options Object
        });
      }
    },
    getTimeSinceCreation(creationDate) {
      return `Publicado ${formatDistanceToNow(parseISO(creationDate), { locale: ptBR })} atrás`;
    },
    truncatedText(fullText) {
      return this.$filters.truncate(fullText, 140, '...');
    },
    isTruncatedText(fullText) {
      return fullText.length >= 140;
    },
    async openFloatingDiv(publicationId) {
      console.log('Botão clicado, ID:', publicationId);

      this.floatingDiv.showFloatingDiv = true;

      try {
        const response = await this.$axios.get(`/publication-card/id/${publicationId}`);

        this.floatingDiv.title = response.data.title;
        this.floatingDiv.paragraph = response.data.paragraph;
        this.floatingDiv.imgName = response.data.imgName;

        console.log('publication float data', response.data)
      } catch (error) {
        console.error("Erro ao buscar dados para div flutuante da home:", error);

        this.title = 'Erro ao carregar dados';
        this.imageSrc = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-vector%2F400-error-bad-request-concept-illustration_8030432.htm&psig=AOvVaw1RxvidgBaAKPNhrBHp_Yqm&ust=1722397174212000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCKCtztLrzYcDFQAAAAAdAAAAABAJ';
      }
    },
    closeFloatingDiv() {
      this.floatingDiv.showFloatingDiv = false;
    }
  }
}
</script>

<style>

.bg-custom-primary {
  background: #01aae1;
}

.bg-custom-secondary {
  background: #7cb637;
}

.custom-icon-size {
  font-size: 70px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
}

.floating-div {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

.floating-div .container {
  max-width: 100%;
}

.floating-div img {
  max-width: 100%;
}

.link-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

.link-button:hover {
  color: #0056b3;
}

.content-container {
  max-height: 80vh; /* Altura máxima do conteúdo interno */
  overflow-y: auto; /* Adiciona o scroll vertical */
}
</style>