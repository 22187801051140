<template>
    <div class="container">
        <footer class="py-5">
            <div class="row">
                <div class="col-6">
                    <div class="d-flex flex-column">
                        <h5>Instituto Castelo Branco</h5>
                        <ul class="nav flex-column">
                            <li>
                                <router-link to="/admin/login" class="nav-link mb-2 p-0"
                                    :class="{ active: $route.path === '/login' }">Pagina do Admin</router-link>
                            </li>
                        </ul>
                        <p class="nav-link p-0 text-muted mb-2">Siga nossas redes sociais.
                        </p>
                        <ul class="list-unstyled d-flex mb-0 align-items-start">
                            <li><a class="link-dark text-primary"
                                    href="https://www.facebook.com/institutocastelobranconiquelandia?locale=pt_BR"
                                    target="_blank"><i class="bi bi-facebook icon-25"></i></a></li>
                            <li class="ms-3"><a class="link-dark text-danger"
                                    href="https://www.instagram.com/institutocastelobranco/?igsh=MXZ1cmpqcTRuazViYw%3D%3D"
                                    target="_blank"><i class="bi bi-instagram icon-25 p-2"></i></a></li>
                            <li class="ms-3"><a class="link-dark text-success" href="https://wa.me/5562985815776"
                                    target="_blank"><i class="bi bi-whatsapp icon-25"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-6">
                    <h5>Transforme sua ideia em um site incrível!</h5>
                    <p class="nav-link p-0 text-muted mb-2">Entre em contato comigo através das minhas redes sociais
                    </p>
                    <ul class="list-unstyled d-flex mb-2">
                        <li>
                            <a href="https://www.linkedin.com/in/fabricio-patrocinio/" target="_blank">
                                <i class="bi bi-linkedin icon-25"></i>
                            </a>
                        </li>
                        <li class="ms-3"><a class="link-dark text-danger"
                                href="https://www.instagram.com/fabricio_patrocinio_/"
                                target="_blank"><i class="bi bi-instagram icon-25 p-2"></i></a></li>
                        <li class="ms-3"><a class="link-dark text-success" href="https://wa.me/5586994230455"
                                target="_blank"><i class="bi bi-whatsapp icon-25"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>
                    © 2024 Instituto castelo branco, Inc. Todos os direitos
                    reservados.
                </p>

            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style>
.icon-25 {
    font-size: 25px;
}
</style>